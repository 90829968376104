var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useState } from "react";
import { agreements, env, longDays, news, professions, shortDays, siteSettings, warninLogStyle, } from "../constants/global.constants";
import { api, ApiService } from "./http.service";
import { modalServiceSubject } from "./modal.service";
import { Storage } from "./localstorege.service";
import { agreementSubject, newsSubject, underServiceSubject } from "../store/misc.store";
import { auth } from "../store/auth.store";
import { toast } from "react-toastify";
import { isMobile } from "../App";
import { getGameRemains } from "./auth.service";
import { sha256 } from "js-sha256";
import { cartStore } from "../store/cart.store";
import paths from "../paths";
import { wssProvider } from "./websocket.service";
import _ from "lodash";
var offset = new Date().getTimezoneOffset() / 60;
export var ServerTimeDifference = 0;
export function getServerTime() {
    var browserDate = new Date();
    api
        .start("get", env.accounting_api + "/misc/api/get-current-date/?v=" + Math.random().toString(), null, false)
        .then(function (response) {
        if (response.status) {
            var serverDate = new Date(response.data.currentDate);
            // @ts-ignore
            ServerTimeDifference =
                serverDate.getTime() + serverDate.getTimezoneOffset() * 60000 - browserDate.getTime() + 3600000 * 3;
            console.log("%cBrowser timedelta: " + ServerTimeDifference + " saniye", warninLogStyle);
            // console.log("%cTarayıcı Zamanı: "+browserDate, warninLogStyle);
            // console.log("%cSunucu Zamanı: "+new Date(response.data.currentDate), warninLogStyle);
            // console.log("New date time",new Date(browserDate.getTime() + ServerTimeDifference));
        }
    })
        .catch(function (e) { });
}
getServerTime();
export function ServerDate() {
    return new Date(new Date().getTime() + ServerTimeDifference);
}
export function calcTimeTurkey() {
    var date = ServerDate();
    var utc = date.getTime() + date.getTimezoneOffset() * 60000;
    var trDate = new Date(utc + 3600000 * 3);
    return trDate;
}
export function useForceUpdate() {
    // @ts-ignore
    var _a = useState(0), value = _a[0], setValue = _a[1]; // integer state
    return function () { return setValue(function (value) { return value + 1; }); }; // update the state to force render
}
export function dateFormat(eventDate) {
    var date = new Date(eventDate);
    date = new Date(date.setTime(date.getTime() - offset));
    return date.toLocaleDateString();
}
export function dateTimeFormat(eventDate) {
    var date = new Date(eventDate);
    date = new Date(date.setTime(date.getTime() - offset));
    return date.toLocaleDateString() + " " + date.toLocaleTimeString().slice(0, 5);
}
export var currencyFormatter = new Intl.NumberFormat("tr-TR", {
    style: "currency",
    currency: "TRY",
    // minimumFractionDigits: 0, // removes decimal places if desired
});
var monthNames = {
    "0": "Ocak",
    "1": "Şubat",
    "2": "Mart",
    "3": "Nisan",
    "4": "Mayıs",
    "5": "Haziran",
    "6": "Temmuz",
    "7": "Ağustos",
    "8": "Eylül",
    "9": "Ekim",
    "10": "Kasım",
    "11": "Aralık",
};
export function getDateTimeHumanize(eventDate, t) {
    if (t === void 0) { t = "long"; }
    var now = ServerDate();
    var date1 = new Date(now.toISOString());
    var date2 = new Date(eventDate);
    var Difference_In_Days = date2.getDate() - date1.getDate();
    var day = "";
    if (Difference_In_Days == 0) {
        day = "Bugün";
    }
    else if (Difference_In_Days == -1) {
        day = "Dün";
    }
    else if (Difference_In_Days == 1) {
        day = "Yarın";
    }
    else if (Difference_In_Days > 6) {
        day = date2.getDate() + " " + monthNames[String(date2.getMonth())];
    }
    else {
        if (t === "long") {
            day = longDays[date2.getDay()];
        }
        else {
            day = shortDays[date2.getDay()];
        }
    }
    return day + " " + date2.toLocaleTimeString().slice(0, 5);
}
export function showAgreementModal(key) {
    var content = agreements[key];
    modalServiceSubject.next({
        title: content.title,
        content: content.content,
        width: 720,
        confirm: {
            cancel: {
                class: "btn w-100 custom-btn-danger me-2",
                label: "Kapat",
            },
        },
    });
}
export function showSelfieRules() {
    var content = {
        title: "Kurallar",
        content: "\u2714 Yaln\u0131zca kendinize ait bir foto\u011Fraf y\u00FCkleyin.<br/>\n\u2714 Y\u00FCz\u00FCn\u00FCz a\u00E7\u0131k ve net bir \u015Fekilde g\u00F6r\u00FCnmelidir.<br/>\n\u2714 Uygunsuz i\u00E7erik, ba\u015Fkalar\u0131na ait veya manip\u00FCle edilmi\u015F g\u00F6rseller y\u00FCklemek yasakt\u0131r.<br/>\n\uD83D\uDCC2 Desteklenen formatlar: JPG, PNG (Maksimum 5MB)\"",
    };
    modalServiceSubject.next({
        title: content.title,
        content: content.content,
        width: 720,
        confirm: {
            cancel: {
                class: "btn w-100 custom-btn-danger me-2",
                label: "Kapat",
            },
        },
    });
}
export function showSelfieExample() {
    var content = {
        title: "Örnek Görsel",
        content: "<img width=\"200\" height=\"200\" src=\"./assets/images/kyc_example.jpeg\" />",
    };
    modalServiceSubject.next({
        title: content.title,
        content: content.content,
        width: 720,
        confirm: {
            cancel: {
                class: "btn w-100 custom-btn-danger me-2",
                label: "Kapat",
            },
        },
    });
}
export function exceptionModal(e, option) {
    if (option === void 0) { option = null; }
    var defaultOption = {
        title: "Beklenmedik Bir Hata!",
        content: "<p>".concat(e, "</p>"),
        timeout: 5000,
        confirm: {
            cancel: {
                label: "Tamam",
            },
        },
    };
    if (option) {
        for (var _i = 0, _a = Object.entries(option); _i < _a.length; _i++) {
            var _b = _a[_i], k = _b[0], v = _b[1];
            defaultOption[k] = v;
        }
    }
    modalServiceSubject.next(defaultOption);
}
export function CurrencyFormat(price, decimal) {
    if (decimal === void 0) { decimal = 2; }
    if (decimal === 0) {
        return Number(price)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&#")
            .slice(0, -3)
            .replaceAll("#", ".");
    }
    return toFixedRound(Number(price), Number(decimal))
        .replace(/\d(?=(\d{3})+\.)/g, "$&#")
        .replaceAll(".", ",")
        .replaceAll("#", ".");
}
export function toFixedRound(value, pow) {
    var i = String(Number(value)).split(".");
    if (pow === 0) {
        return i[0];
    }
    return i[0] + "." + (i.length === 1 ? "00000000000000000".slice(0, pow) : (i[1] + "00000000000000000").slice(0, pow));
}
export function getAgreements() {
    return __awaiter(this, void 0, void 0, function () {
        var existingAgreements, _i, _a, _b, k, v;
        return __generator(this, function (_c) {
            existingAgreements = Storage.get("agreements");
            if (existingAgreements) {
                for (_i = 0, _a = Object.entries(existingAgreements); _i < _a.length; _i++) {
                    _b = _a[_i], k = _b[0], v = _b[1];
                    agreements[k] = v;
                }
                agreementSubject.next({ action: "loaded" });
                return [2 /*return*/, agreements];
            }
            api
                .start("get", env.accounting_api + "/misc/api/list-agreement/", null, false)
                .then(function (response) {
                if (response.status) {
                    for (var _i = 0, _a = response.data; _i < _a.length; _i++) {
                        var item = _a[_i];
                        agreements[item.slug] = item;
                    }
                    Storage.set("agreements", agreements, 60 * 1000);
                    agreementSubject.next({ action: "loaded" });
                }
            })
                .catch(function (e) {
                return null;
            })
                .finally(function () {
                return agreements;
            });
            return [2 /*return*/];
        });
    });
}
export function getSiteSettings() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            api
                .start("get", env.accounting_api + "/site_setting/api/get-site-setting/", null, false)
                .then(function (response) {
                if (response.status) {
                    for (var _i = 0, _a = response.data; _i < _a.length; _i++) {
                        var item = _a[_i];
                        siteSettings[item.name] = item.value;
                    }
                    if (siteSettings["bakimModu"] && (siteSettings["bakimModu"] == "True" || siteSettings["bakimModu"] == "true")) {
                        underServiceSubject.next(true);
                    }
                    return siteSettings;
                }
            })
                .catch(function (e) {
                return null;
            })
                .finally(function () {
                return siteSettings;
            });
            return [2 /*return*/];
        });
    });
}
export function copyMemberNumber(text, message) {
    if (message === void 0) { message = null; }
    navigator.clipboard.writeText(text).then(function () {
        toast.success(message ? message : "Kopyalandı!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        });
    });
}
export function loadingAnimation(state) {
    var loading = document.getElementById("id_loaderAnimation");
    if (loading) {
        if (state) {
            loading.style.display = "block";
        }
        else {
            loading.style.display = "none";
        }
    }
}
export function slugify(str) {
    str = str.replace(/^\s+|\s+$/g, ""); // trim leading/trailing white space
    str = str.toLowerCase(); // convert string to lowercase
    str = str
        .replace(/[^a-z0-9 -]/g, "") // remove any non-alphanumeric characters
        .replace(/\s+/g, "-") // replace spaces with hyphens
        .replace(/-+/g, "-"); // remove consecutive hyphens
    return str;
}
export var gameNavigationMiddleware = function (game, navigate) {
    var isLogin = auth.authentication && auth.member && auth.member.id;
    if (!game.is_active && auth.remaining_games.includes(game.id)) {
        return;
    }
    if (game.is_active) {
        navigate("".concat(game.code === "kazi_kazan" ? "" : paths.games.url, "/").concat(slugify(game.name
            .toLowerCase()
            .replaceAll("ü", "u")
            .replaceAll("ş", "s")
            .replaceAll("ç", "c")
            .replaceAll("ğ", "g")
            .replaceAll("ı", "i")
            .replaceAll("ö", "o"))));
        return;
    }
    if (isLogin && !game.is_active) {
        api
            .start("post", env.accounting_api + "/misc/api/create-game-reminder/", { game_id: game.id }, true)
            .then(function (res) {
            if (res && res.status) {
                game.reminder = true;
                getGameRemains();
                modalServiceSubject.next({
                    case: "info",
                    title: "Hatırlatma Oluşturuldu",
                    content: "<p class=\"text-center\">".concat(game.name, " \u00E7ok yak\u0131nda e-\u015Eans'ta. Sizi haberdar edece\u011Fiz.</p>"),
                    confirm: {
                        cancel: {
                            label: "Tamam",
                            class: "btn btn-primary w-100",
                        },
                    },
                });
            }
        });
        return;
    }
};
export function loginRequired(navigate) {
    var _a;
    if ((_a = auth.member) === null || _a === void 0 ? void 0 : _a.id) {
        return true;
    }
    else {
        setTimeout(function () {
            if (isMobile) {
                navigate(paths.landing);
            }
            else {
                modalServiceSubject.next({
                    class: "success",
                    title: "Üye Girişi",
                    content: "<p>Bu i\u015Flemi yapabilmeniz i\u00E7in \u00F6nce oturum a\u00E7man\u0131z gerekli. \u00DCye de\u011Filseniz hemen \u00FCye olabilir ya da giri\u015F yaparak al\u0131\u015Fveri\u015Finizi tamamlayabilirsiniz.</p>",
                    confirm: {
                        sure: {
                            label: "Giriş Yap",
                            class: "btn btn-success",
                            action: function () {
                                // loginModalSubject.next('show');
                                navigate(paths.login);
                            },
                        },
                        cancel: {
                            label: "Üye Ol",
                            class: "btn btn-info",
                            action: function () {
                                navigate(paths.register);
                            },
                        },
                    },
                });
            }
        }, 300);
    }
    return false;
}
export var getSHA256Hash = function (input) { return __awaiter(void 0, void 0, void 0, function () {
    var hash, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, sha256.hex(input.toString())];
            case 1:
                hash = _a.sent();
                return [2 /*return*/, hash];
            case 2:
                e_1 = _a.sent();
                console.log(e_1);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var gtmViewItemList = function (itemArray, categoryName) {
    /**
       Kullanıcı herhangi bir ödül, oyun veya çekiliş listesini görüntülediğinde view_item_list eventi pushlanmalıdır.
       Event ile birlikte datalayer içinde event parametreleri ve ödül, oyun veya çekiliş bilgilerini içeren item array’i bulunmalıdır. Normalde kullanıcı o an listede kaç ödül/oyun/çekiliş görüyor ise item array’i o kadar ödül/oyun/çekiliş içermelidir.
       Item_list_name’ler görüntülenen itemin kategorisine göre değişkenlik göstermektedir.
       Bir ödül/oyun/çekilişin ilk görüntülendiği liste adı işlem yapılana kadar yani purchase’a kadar hep aynı olmalıdır.
       Bu şekilde listelerin performanslarını da değerlendirebileceğiz.
  
       **/
    try {
        var items = [];
        if (itemArray.length > 0) {
            for (var _i = 0, itemArray_1 = itemArray; _i < itemArray_1.length; _i++) {
                var item = itemArray_1[_i];
                if (categoryName == "Çekiliş") {
                    items.push({
                        item_id: item.no,
                        item_name: item.title_abbreviated,
                        item_category: categoryName,
                        price: item.ticket_price,
                        quantity: 1,
                    });
                }
                else if (categoryName == "Oyun") {
                    items.push({
                        item_id: item.code,
                        item_name: item.name,
                        item_category: categoryName,
                        price: 1,
                        quantity: 1,
                    });
                }
                else {
                    items.push({
                        item_id: item.code,
                        item_name: item.name,
                        item_category: categoryName,
                        price: item.price,
                        quantity: 1,
                    });
                }
            }
            // @ts-ignore
            dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
            // @ts-ignore
            dataLayer.push({
                event: "view_item_list",
                ecommerce: {
                    item_list_id: categoryName == "Çekiliş" ? "1" : categoryName == "Oyunlar" ? 2 : "3",
                    item_list_name: categoryName,
                    items: items,
                },
            });
        }
    }
    catch (e) {
        console.log(e);
    }
};
export var gtmSelectItem = function (itemArray, categoryName) {
    /**
       Herhangi bir listeden oyun/çekiliş/ödülün detayına gitmek için üzerine tıklandığında select_item eventi pushlanmalıdır.
       **/
    try {
        var items = [];
        if (itemArray.length > 0) {
            for (var _i = 0, itemArray_2 = itemArray; _i < itemArray_2.length; _i++) {
                var item = itemArray_2[_i];
                if (categoryName == "Çekiliş") {
                    items.push({
                        item_id: item.no,
                        item_name: item.title_abbreviated,
                        item_category: categoryName,
                        price: item.ticket_price,
                        quantity: 1,
                    });
                }
                else if (categoryName == "Oyunlar") {
                    items.push({
                        item_id: item.code,
                        item_name: item.name,
                        item_category: categoryName,
                        price: 1,
                        quantity: 1,
                    });
                }
                else {
                    items.push({
                        item_id: item.code,
                        item_name: item.name,
                        item_category: categoryName,
                        price: item.price,
                        quantity: 1,
                    });
                }
            }
            // @ts-ignore
            dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
            // @ts-ignore
            dataLayer.push({
                event: "select_item",
                ecommerce: {
                    item_list_id: categoryName == "Çekiliş" ? "1" : categoryName == "Oyunlar" ? 2 : "3",
                    item_list_name: categoryName,
                    items: items,
                },
            });
        }
    }
    catch (e) { }
};
export var gtmViewItem = function (itemArray, categoryName) {
    /**
       Kullanıcı bir oyun/çekiliş/ödülün detay sayfasını görüntülediğinde view_item eventi pushlanmalıdır.
       Item_list_id ve item_list_name, oyun/çekiliş/ödüllerin bulunduğu liste adları olarak basılmalıdır.
       **/
    try {
        var items = [];
        for (var _i = 0, itemArray_3 = itemArray; _i < itemArray_3.length; _i++) {
            var item = itemArray_3[_i];
            if (categoryName == "Çekiliş") {
                items.push({
                    item_id: item.no,
                    item_name: item.title_abbreviated,
                    item_category: categoryName,
                    price: item.ticket_price,
                    quantity: 1,
                });
            }
            else if (categoryName == "Oyunlar") {
                items.push({
                    item_id: item.code,
                    item_name: item.name,
                    item_category: categoryName,
                    price: 1,
                    quantity: 1,
                });
            }
            else {
                items.push({
                    item_id: item.code,
                    item_name: item.name,
                    item_category: categoryName,
                    price: item.price,
                    quantity: 1,
                });
            }
        }
        // @ts-ignore
        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        // @ts-ignore
        dataLayer.push({
            event: "view_item",
            ecommerce: {
                currency: "TRY",
                value: itemArray[0].item.draw_price,
                items: items,
            },
        });
    }
    catch (e) { }
};
export var gtmAddToCart = function (itemArray, categoryName) {
    /**
       Kullanıcı herhangi bir sepete ürün ekleme alanında sepete ürün attığında pushlanması gereken event.
  
       **/
    try {
        var items = [];
        var totalPrice = 0;
        for (var _i = 0, itemArray_4 = itemArray; _i < itemArray_4.length; _i++) {
            var item = itemArray_4[_i];
            items.push({
                item_id: item.item.draw_id,
                item_name: item.item.title_abbreviated,
                item_category: categoryName,
                price: item.item.draw_price,
                quantity: item.quantity,
            });
            totalPrice += Number(item.quantity) * Number(item.item.draw_price);
        }
        // @ts-ignore
        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        // @ts-ignore
        dataLayer.push({
            event: "add_to_cart",
            ecommerce: {
                currency: "TRY",
                value: totalPrice,
                items: items,
            },
        });
    }
    catch (e) { }
};
export var gtmViewCart = function (itemArray, categoryName) {
    /**
       Kullanıcı sepetini görüntülediğinde pushlanması gereken event.
       Fakat sitede ki yapıdan kaynaklı kullanıcı çekiliş sayfasına land ettiğinde sepet otomatik olarak açıldığı için sadece anasayfa’da “Alışverişi Tamamla” butonuna tıklandığında gönderilmesi gereken event.
  
       **/
    try {
        var items = [];
        var totalPrice = 0;
        for (var _i = 0, itemArray_5 = itemArray; _i < itemArray_5.length; _i++) {
            var item = itemArray_5[_i];
            items.push({
                item_id: item.item.draw_id,
                item_name: item.item.title_abbreviated,
                item_category: categoryName,
                price: item.item.draw_price,
                quantity: item.quantity,
            });
            totalPrice += Number(item.quantity) * Number(item.item.draw_price);
        }
        // @ts-ignore
        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        // @ts-ignore
        dataLayer.push({
            event: "view_cart",
            ecommerce: {
                currency: "TRY",
                value: totalPrice,
                items: items,
            },
        });
    }
    catch (e) { }
};
export var gtmRemoveFromCart = function (itemArray, categoryName) {
    /**
       Kullanıcı sepetinden bir ürün çıkardığın pushlanması gereken event.
       **/
    try {
        var items = [];
        var totalPrice = 0;
        for (var _i = 0, itemArray_6 = itemArray; _i < itemArray_6.length; _i++) {
            var item = itemArray_6[_i];
            items.push({
                item_id: item.item.draw_id,
                item_name: item.item.title_abbreviated,
                item_category: categoryName,
                price: item.item.draw_price,
                quantity: item.quantity,
            });
            totalPrice += Number(item.quantity) * Number(item.item.draw_price);
        }
        // @ts-ignore
        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        // @ts-ignore
        dataLayer.push({
            event: "remove_from_cart",
            ecommerce: {
                currency: "TRY",
                value: totalPrice,
                items: items,
            },
        });
    }
    catch (e) { }
};
export var gtmBeginCheckout = function () {
    /**
       Kullanıcı “Alışverişi Tamamla” butonuna tıkladığında pushlanması gereken event
       **/
    try {
        var items = [];
        var totalPrice = 0;
        for (var _i = 0, _a = cartStore.items; _i < _a.length; _i++) {
            var item = _a[_i];
            items.push({
                item_id: item.item.draw_id,
                item_name: item.item.title_abbreviated,
                item_category: "Çekiliş",
                price: item.item.draw_price,
                quantity: item.quantity,
            });
            totalPrice += Number(item.quantity) * Number(item.item.draw_price);
        }
        // @ts-ignore
        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        // @ts-ignore
        dataLayer.push({
            event: "begin_checkout",
            ecommerce: {
                currency: "TRY",
                value: totalPrice,
                items: items,
            },
        });
    }
    catch (e) { }
};
export var gtmViewPromotion = function (slideName, image, campaignId, campaignName) {
    /***
       Kullanıcı anasayfada bulunan bannerları görüntülediğinde tetiklenmesi gereken event.
       Carousel bannerlar için, eğer kullanıcı banner ile etkileşime geçmezse, bannerlar 1 tam tur döndüğünde bu eventin tetiklenmesi durmalıdır.
       Kullanıcı slider banner ile tekrar etkileşime geçtiğinde event tekrar tetiklenmelidir.
       Slider olmayan statik bannerlar için (anasayfanın sağ köşesinde listelenen), kullanıcı bannerı görüntülediğinde, görüntülenen banner sayısı kadar bu event tetiklenmelidir.
       ***/
    try {
        // @ts-ignore
        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        // @ts-ignore
        dataLayer.push({
            event: "view_promotion",
            ecommerce: {
                creative_slot: slideName,
                creative_name: image,
                promotion_id: campaignId,
                promotion_name: campaignName,
            },
        });
    }
    catch (e) { }
};
export var gtmSelectPromotion = function (slideName, image, campaignId, campaignName) {
    /**
       Kullanıcı banner’a tıkladığında pushlanması gereken event.
       **/
    try {
        // @ts-ignore
        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        // @ts-ignore
        dataLayer.push({
            event: "select_promotion",
            ecommerce: {
                creative_slot: slideName,
                creative_name: image,
                promotion_id: campaignId,
                promotion_name: campaignName,
            },
        });
    }
    catch (e) { }
};
export var gtmCashOut = function (bankName, amount) {
    try {
        // @ts-ignore
        dataLayer.push({
            event: "cashOut",
            bankaAdı: bankName,
            tutar: amount,
            balance: auth.member.balance_debit,
        });
    }
    catch (e) { }
};
export var gtmUserEvent = function (eventName) {
    try {
        // @ts-ignore
        dataLayer.push({
            event: eventName,
            user_id: auth.member.id,
        });
    }
    catch (e) { }
};
export var getGameItem = function (gameCode) {
    try {
        var games = Storage.get("games");
        var game = games.find(function (r) { return r.code === gameCode; });
        return game;
    }
    catch (e) {
        return null;
    }
};
export var getAllGames = function () { return __awaiter(void 0, void 0, void 0, function () {
    var games;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                games = Storage.get("games");
                if (games) {
                    return [2 /*return*/, games];
                }
                return [4 /*yield*/, api.start("get", env.accounting_api + "/games/api/list-game/", null, false).then(function (res) {
                        if (res && res.status) {
                            var d = _.orderBy(res.data, ["ordering"], "asc");
                            Storage.set("games", d, 60 * 60 * 5);
                            return d;
                        }
                        else {
                            return [];
                        }
                        return [];
                    })];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getGameItemFromSlug = function (gameSlug) { return __awaiter(void 0, void 0, void 0, function () {
    var games, game, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, getAllGames()];
            case 1:
                games = _a.sent();
                console.log(games);
                game = games.find(function (r) {
                    console.log(slugify(r.name));
                    console.log(gameSlug);
                    return (slugify(r.name
                        .toLowerCase()
                        .replaceAll("ü", "u")
                        .replaceAll("ş", "s")
                        .replaceAll("ç", "c")
                        .replaceAll("ğ", "g")
                        .replaceAll("ı", "i")
                        .replaceAll("ö", "o")) === gameSlug);
                });
                return [2 /*return*/, game];
            case 2:
                e_2 = _a.sent();
                console.log(e_2);
                return [2 /*return*/, null];
            case 3: return [2 /*return*/];
        }
    });
}); };
export function getNews() {
    return __awaiter(this, void 0, void 0, function () {
        var existingNews, api;
        return __generator(this, function (_a) {
            existingNews = Storage.get("news");
            if (existingNews) {
                Object.assign(news, existingNews);
                newsSubject.next({ action: "loaded" });
            }
            api = new ApiService();
            api
                .start("get", env.accounting_api + "/misc/api/list-news/", null, false)
                .then(function (response) {
                if (response.status) {
                    // Object.assign(news, [])
                    response.data.forEach(function (item) {
                        var hasAdded = news.find(function (e) { return e.id === item.id; });
                        if (!hasAdded) {
                            item.is_seen = false;
                            news.push(item);
                        }
                    });
                    Storage.set("news", news, 24 * 60 * 60 * 1000);
                    newsSubject.next({ action: "loaded" });
                }
            })
                .catch(function (e) { })
                .finally(function () { });
            return [2 /*return*/];
        });
    });
}
var dateDifferenceInHours = function (dateInitial, dateFinal) { return (dateFinal - dateInitial) / 3600000; };
var sleep = function (ms) {
    return new Promise(function (resolve) { return setTimeout(resolve, ms); });
};
export function showProfessionModalWss() {
    var showingTimeString = Storage.get("professionModalTime");
    var now = new Date();
    var date1 = new Date(now.toISOString());
    var date2 = new Date(showingTimeString);
    var diffrencesInHours = dateDifferenceInHours(date2, date1);
    if (diffrencesInHours > 48 || showingTimeString == null) {
        var optionValues_1 = '<option value="DEFAULT" disabled>Seç</option>';
        for (var _i = 0, professions_1 = professions; _i < professions_1.length; _i++) {
            var profession = professions_1[_i];
            optionValues_1 += "<option value=".concat(profession.id, " key=").concat(profession.name, ">").concat(profession.name, "</option>");
        }
        setTimeout(function () {
            Storage.set("professionModalTime", date1);
            modalServiceSubject.next({
                class: "info",
                title: "Meslek Bilginizi Seçin",
                content: "<p>Yasal gereklilikler nedeniyle i\u015F ve meslek bilginizi kaydetmeniz gerekmektedir. L\u00FCtfen i\u015F ve meslek bilginizi se\u00E7iniz</p><div><select class=\"mb-2 w-100 rounded p-1\" value=\"DEFAULT\" id=\"professionSelect\">".concat(optionValues_1, "</select></div>"),
                confirm: {
                    sure: {
                        label: "Kaydet",
                        action: function () {
                            // @ts-ignore
                            var profession = document.getElementById("professionSelect").value;
                            var apiService = new ApiService();
                            apiService
                                .start("post", env.accounting_api + "/member/api/update-account-info/", {
                                token: Storage.get("token"),
                                profession: profession,
                            }, true)
                                .then(function (response) {
                                sleep(1500);
                                wssProvider.checkauth();
                            })
                                .catch(function (e) {
                                console.log(e);
                            });
                        },
                    },
                },
                timeout: 0,
            });
        }, 1000);
    }
}
export var getGames = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, api.start("get", env.accounting_api + "/games/api/list-game/", null, false).then(function (res) {
                    if (res && res.status) {
                        var d = _.orderBy(res.data, ["ordering"], "asc");
                        Storage.set("games", d, 60 * 60 * 5);
                    }
                    else {
                        return [];
                    }
                    return [];
                })];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
