import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import "./game.css";
import { getGameItem, slugify, useForceUpdate } from "../../../services/misc.functions";
import { auth, authSubject } from "../../../store/auth.store";
import { api } from "../../../services/http.service";
import { env } from "../../../constants/global.constants";
import { GameLogin } from "../game.login";
import { GameInfo } from "../game.info";
import { Storage } from "../../../services/localstorege.service";
import _ from "lodash";
import { useParams } from "react-router";
import { isMobile } from "../../../App";
function Game(props) {
    var code = props.code;
    var _a = useState(null), game = _a[0], setGame = _a[1];
    var params = useParams();
    useEffect(function () {
        // console.log(code);
        if (code) {
            var game_1 = getGameItem(code);
            if (game_1 != null && game_1.is_active) {
                setGame(game_1);
            }
        }
    }, [code]);
    useEffect(function () {
        var cartwidget = document.getElementById("cartwidget");
        var footerSection = document.getElementById("footerSection");
        var mainWrapper = document.getElementById("mainWrapper");
        cartwidget.style.display = "none";
        if (isMobile) {
            mainWrapper.className = "main-wrapper col-lg-12";
        }
        else {
            mainWrapper.className = "main-wrapper col-lg-12";
        }
        footerSection.style.display = "none";
    }, []);
    useEffect(function () {
        console.log('HEERREEE');
        var slug = params.subpage;
        console.log(slug);
        var _games = Storage.get("games");
        if (_games) {
            var selectedGame = _games.find(function (r) {
                return slugify(r.name.toLowerCase()
                    .replaceAll("ü", "u")
                    .replaceAll("ş", "s")
                    .replaceAll("ç", "c")
                    .replaceAll("ğ", "g")
                    .replaceAll("ı", "i")
                    .replaceAll("ö", "o")) === slug;
            });
            if (selectedGame.is_active) {
                setGame(selectedGame);
            }
        }
        else {
            api.start("get", env.accounting_api + "/games/api/list-game/", null, false).then(function (res) {
                if (res && res.status) {
                    var d = _.orderBy(res.data, ["ordering"], "asc");
                    Storage.set("games", d, 60 * 60 * 5);
                    var selectedGame = d.find(function (r) {
                        return slugify(r.name.toLowerCase()
                            .replaceAll("ü", "u")
                            .replaceAll("ş", "s")
                            .replaceAll("ç", "c")
                            .replaceAll("ğ", "g")
                            .replaceAll("ı", "i")
                            .replaceAll("ö", "o")) === slug;
                    });
                    if (selectedGame.is_active) {
                        setGame(selectedGame);
                    }
                }
                else {
                }
            });
        }
        // console.log(game);
        // if (game != null) {
        //     setGame(game);
        // }
        // console.log(game);
    }, [params]);
    var forceUpdate = useForceUpdate();
    useEffect(function () {
        var auth_subscriber = authSubject.subscribe(function () {
            forceUpdate();
        });
        return function () {
            auth_subscriber.unsubscribe();
        };
    }, []);
    return (_jsxs("div", { children: [auth.authentication && auth.member && auth.member.id && game ? (_jsx("iframe", { src: "".concat(env.web_link, "/game-widget/game-widget.html?&token=").concat(Storage.get("token"), "&vendor=").concat(game.vendor, "&code=").concat(game.code, "&userId=").concat(auth.member.id) })) : (_jsx(GameLogin, {})), _jsx(GameInfo, { code: "9043" })] }));
}
export default Game;
