var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./campaign.css";
import { useEffect, useState } from "react";
import { api } from "../../services/http.service";
import { env } from "../../constants/global.constants";
import { isMobile } from "../../App";
import Modal from "react-modal";
import _ from "lodash";
import { auth } from "../../store/auth.store";
import { modalServiceSubject } from "../../services/modal.service";
import { useNavigate } from "react-router";
import paths from "../../paths";
export function Campaigns() {
    var navigate = useNavigate();
    var _a = useState([]), dataList = _a[0], setDataList = _a[1];
    var _b = useState(null), campaignModal = _b[0], setCampaignModal = _b[1];
    var _c = useState(0), selectedTab = _c[0], setSelectedTab = _c[1];
    var joinCampaign = function (id) {
        if (auth.member) {
            api
                .start("post", env.accounting_api + "/misc/api/create-member-campaign/", { campaign_id: id }, true)
                .then(function (res) {
                if (res && res.status) {
                    getCampaigns();
                    modalServiceSubject.next({
                        case: "success",
                        title: "Başarılı!",
                        content: "<p class=\"text-center\">Kampanya'ya kat\u0131l\u0131m\u0131n\u0131z ger\u00E7ekle\u015Fmi\u015Ftir.</p>",
                        confirm: {
                            cancel: {
                                label: "Tamam",
                                class: "btn-success w-100",
                                action: function () {
                                    setCampaignModal(null);
                                },
                            },
                        },
                    });
                }
                else {
                    modalServiceSubject.next({
                        case: "danger",
                        title: "İşlem Başarısız!",
                        content: "<p class=\"text-center\">".concat(res.message ? res.message : "", "</p>"),
                        confirm: {
                            cancel: {
                                class: "btn-danger",
                                label: "Tamam",
                            },
                        },
                    });
                }
            });
        }
        else {
            modalServiceSubject.next({
                class: "success",
                title: "Üye Girişi",
                content: "<p>Kampanya'ya kat\u0131lmak i\u00E7in \u00F6nce oturum a\u00E7man\u0131z gerekli. \u00DCye de\u011Filseniz hemen \u00FCye olabilir yada giri\u015F yaparak kampanya'ya kat\u0131labilirsiniz.</p>",
                confirm: {
                    sure: {
                        label: "Giriş Yap",
                        class: "btn btn-success",
                        action: function () {
                            navigate(paths.login.url);
                        },
                    },
                    cancel: {
                        label: "Üye Ol",
                        class: "btn btn-info",
                        action: function () {
                            navigate(paths.register.url);
                        },
                    },
                },
            });
        }
    };
    var getCampaigns = function () {
        if (auth.member) {
            api
                .start("get", env.accounting_api + "/misc/api/list_campaign_control/", null, true)
                .then(function (res) {
                // console.log(res)
                if (res && res.status) {
                    setDataList(_.orderBy(res.data, ["is_valid", "create_date"], ["desc"]));
                }
            });
        }
        else {
            api
                .start("get", env.accounting_api + "/misc/api/list-campaign/", null, true)
                .then(function (res) {
                // console.log(res)
                if (res && res.status) {
                    // remove the campaign with id 131. papara 150 kampanysı sorgudan ayıklanır.
                    setDataList(_.orderBy(res.data, ["is_valid", "create_date"], ["desc"]).filter(function (el) { return el.id !== 131; }));
                }
            });
        }
    };
    useEffect(function () {
        getCampaigns();
    }, []);
    useEffect(function () {
        // when campaignModal is opened, prevent scroll on page and allow scroll only modal content
        if (campaignModal !== null) {
            document.body.style.overflow = "hidden";
        }
        else {
            document.body.style.overflow = "auto";
        }
    }, [campaignModal]);
    // handle keyboard events and scroll on modal by keyboard
    var handleKeyboard = function (e) {
        if (campaignModal !== null) {
            document.addEventListener("keydown", function (event) {
                // if arrow down is pressed, scroll down modal content, if arrow up is pressed, scroll up modal content
                if (event.key === "ArrowDown") {
                    var modalContent = document.querySelector(".modal-body");
                    if (modalContent) {
                        modalContent.scrollTop += 10;
                    }
                }
                else if (event.key === "ArrowUp") {
                    var modalContent = document.querySelector(".modal-body");
                    if (modalContent) {
                        modalContent.scrollTop -= 10;
                    }
                }
            });
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "campaigns" }, { children: [isMobile && (_jsx("div", __assign({ className: "row inner-page-top-tabs mb-4" }, { children: _jsxs("div", __assign({ className: "liner-tabs", style: { "--tab-count": "2" } }, { children: [_jsx("input", { type: "radio", value: "0", checked: selectedTab === 0, "data-rel": "tab1", id: "raffle-id_tab-1", onChange: function () {
                                        setSelectedTab(0);
                                    }, name: "tabs" }), _jsx("label", __assign({ className: "tab text-center", htmlFor: "raffle-id_tab-1" }, { children: "G\u00FCncel Kampanyalar\u0131m\u0131z" })), _jsx("input", { type: "radio", value: "1", "data-rel": "tab2", checked: selectedTab === 1, id: "raffle-id_tab-2", onChange: function () {
                                        setSelectedTab(1);
                                    }, name: "tabs" }), _jsx("label", __assign({ className: "tab text-center", htmlFor: "raffle-id_tab-2" }, { children: "Ge\u00E7mi\u015F Kampanyalar\u0131m\u0131z" })), _jsx("span", { className: "glider" })] })) }))), !isMobile && (_jsx("div", __assign({ className: "row" }, { children: _jsx("div", __assign({ className: " mb-3" }, { children: _jsxs("div", __assign({ className: "slider-tabs", style: { "--tab-count": "2" } }, { children: [_jsx("input", { type: "radio", value: "0", checked: selectedTab === 0, "data-rel": "tab1", id: "raffle-id_tab-1", onChange: function () {
                                            setSelectedTab(0);
                                        }, name: "tabs" }), _jsx("label", __assign({ className: "tab", htmlFor: "raffle-id_tab-1" }, { children: _jsx("h1", __assign({ className: "fs-6 fw-bold mb-0" }, { children: "G\u00FCncel Kampanyalar\u0131m\u0131z" })) })), _jsx("input", { type: "radio", checked: selectedTab === 1, value: "1", "data-rel": "tab2", id: "raffle-id_tab-2", onChange: function () {
                                            setSelectedTab(1);
                                        }, name: "tabs" }), _jsx("label", __assign({ className: "tab", htmlFor: "raffle-id_tab-2" }, { children: _jsx("h1", __assign({ className: "fs-6 fw-bold mb-0" }, { children: "Ge\u00E7mi\u015F Kampanyalar\u0131m\u0131z" })) })), _jsx("span", { className: "glider" })] })) })) }))), _jsx("div", __assign({ className: "row" }, { children: dataList.map(function (el, key) {
                            if (selectedTab == 1 && !el.is_valid) {
                                return (_jsx("div", __assign({ className: "col-6 col-mobile-12 campaign-item", onClick: function () {
                                        setCampaignModal(el);
                                    } }, { children: _jsxs("div", __assign({ className: "campaign-widget" }, { children: [!el.is_valid ? (_jsx("div", __assign({ className: "ribbon ribbon-old" }, { children: _jsx("span", { children: "Ge\u00E7mi\u015F" }) }))) : null, _jsx("img", { src: env.cdn_host + el.photo, alt: "" }), _jsxs("div", __assign({ className: "body" }, { children: [_jsx("h2", __assign({ className: "title" }, { children: el.name })), _jsx("span", __assign({ className: "sub-title" }, { children: el.title_abbreviated }))] }))] })) }), key));
                            }
                            else if (selectedTab == 0 && el.is_valid) {
                                return (_jsx("div", __assign({ className: "col-6 col-mobile-12 campaign-item", onClick: function () {
                                        setCampaignModal(el);
                                    } }, { children: _jsxs("div", __assign({ className: "campaign-widget" }, { children: [!el.is_valid ? (_jsx("div", __assign({ className: "ribbon ribbon-old" }, { children: _jsx("span", { children: "Ge\u00E7mi\u015F" }) }))) : null, _jsx("img", { src: env.cdn_host + el.photo, alt: "" }), _jsxs("div", __assign({ className: "body" }, { children: [_jsx("h2", __assign({ className: "title" }, { children: el.name })), _jsx("span", __assign({ className: "sub-title" }, { children: el.title_abbreviated }))] }))] })) }), key));
                            }
                        }) }))] })), _jsx(Modal, __assign({ isOpen: campaignModal !== null, className: "default campaign-detail-modal", onRequestClose: function () {
                    setCampaignModal(null);
                }, onAfterOpen: function (e) {
                    handleKeyboard(e);
                }, contentLabel: "" }, { children: campaignModal && (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "head" }, { children: _jsx("h2", __assign({ style: { height: "unset" } }, { children: campaignModal.name })) })), _jsxs("div", __assign({ className: "modal-body" }, { children: [!campaignModal.is_valid ? (_jsx("div", __assign({ className: "ribbon ribbon-old" }, { children: _jsx("span", { children: "Ge\u00E7mi\u015F" }) }))) : null, _jsx("img", { onClick: function () {
                                        // if (campaignModal.is_member_join) {
                                        //     if (campaignModal.show_button) {
                                        //         joinCampaign(campaignModal.id);
                                        //     }
                                        // }
                                    }, className: "picture mb-3 w-100", src: env.cdn_host + campaignModal.photo, alt: campaignModal.name }), _jsx("h2", { children: "Kampanya Detaylar\u0131" }), _jsx("p", { className: "info", dangerouslySetInnerHTML: {
                                        __html: campaignModal.description.replaceAll("\n", "<br/>"),
                                    } }), _jsx("h2", { children: "Kat\u0131l\u0131m \u015Eartlar\u0131" }), _jsx("p", { className: "info", dangerouslySetInnerHTML: {
                                        __html: campaignModal.participation_requirement.replaceAll("\n", "<br/>"),
                                    } })] })), _jsxs("div", __assign({ className: "modal-ticket-footer text-end" }, { children: [_jsx("div", { className: "wave" }), campaignModal.is_member_join ? (_jsxs("div", __assign({ className: "body-row row" }, { children: [_jsx("div", __assign({ className: "col-6 p-0" }, { children: _jsx("button", __assign({ type: "button", onClick: function () {
                                                    if (campaignModal.show_button) {
                                                        joinCampaign(campaignModal.id);
                                                    }
                                                    else {
                                                        modalServiceSubject.next({
                                                            case: "info",
                                                            title: "Kampanya'ya Katıl",
                                                            content: "<p class=\"text-center\">Bu kampanya'ya daha \u00F6nce kat\u0131lm\u0131\u015Fs\u0131n\u0131z. Bir hata oldu\u011Funu d\u00FC\u015F\u00FCn\u00FCyorsan\u0131z l\u00FCtfen bizimle ileti\u015Fime ge\u00E7in</p>",
                                                            confirm: {
                                                                cancel: {
                                                                    label: "Kapat",
                                                                    class: "btn btn-info w-100",
                                                                },
                                                                sure: {
                                                                    label: "Destek Merkezi",
                                                                    class: "btn btn-warning w-100",
                                                                    action: function () {
                                                                        navigate(paths.support.url + "/cozum-merkezi");
                                                                    },
                                                                },
                                                            },
                                                        });
                                                    }
                                                }, className: "btn w-100 btn-success me-2 opacity-100 ".concat(campaignModal.show_button ? "" : "") }, { children: "Kat\u0131l" })) })), _jsx("div", __assign({ className: "col-6 p-0" }, { children: _jsx("button", __assign({ type: "button", onClick: function () {
                                                    setCampaignModal(null);
                                                }, className: "btn w-100 custom-btn-danger me-2" }, { children: "Kapat" })) }))] }))) : (_jsx("div", __assign({ className: "body-row row" }, { children: _jsx("div", __assign({ className: "col-12" }, { children: _jsx("button", __assign({ type: "button", onClick: function () {
                                                setCampaignModal(null);
                                            }, className: "btn w-100 custom-btn-danger me-2" }, { children: "Kapat" })) })) })))] }))] })) }))] }));
}
