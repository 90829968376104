var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { getRaffleDraws, openRaffleDetail } from "../../services/game.service";
import { drawFilters, raffleDraws } from "../../store/games.store";
import { gtmViewItemList, useForceUpdate } from "../../services/misc.functions";
import { Ticket } from "../../shared/ticket/ticket_bck";
import "./raffle.css";
import { DrawFilter } from "./filter";
import _ from "lodash";
import { useNavigate, useParams } from "react-router";
import { isMobile } from "../../App";
import { MyRaffleOrders } from "./my.raffle.orders";
import { RaffleResults } from "./results";
import { WinnersRaffle } from "./winners";
import { useLocation } from "react-router-dom";
import ScrollToTop from "../../shared/scrolltop/scrollToTop";
import { auth } from "../../store/auth.store";
import paths from "../../paths";
export function RaffleTickets() {
    var forceUpdate = useForceUpdate();
    var params = useParams();
    var navigate = useNavigate();
    var pathname = useLocation().pathname;
    useEffect(function () {
        if (raffleDraws.length > 0 && params.draw_id) {
            var d = raffleDraws.find(function (el) { return el.id === Number(params.draw_id); });
            // console.log("d", d)
            if (d) {
                var queryParams = new URLSearchParams(window.location.search);
                var next = queryParams.get("n");
                if (next) {
                    navigate(next);
                }
                else {
                    // document.title = env.pageTitle;
                    window.history.pushState(null, "", paths.raffleTickets.url);
                }
                openRaffleDetail(d);
            }
        }
    }, [raffleDraws.length]);
    useEffect(function () {
        if (raffleDraws.length === 0) {
            getRaffleDraws().then(function () {
                forceUpdate();
            });
        }
        if (params.subpage) {
            if (["biletlerim", "sonuclar", "kazananlar-kulubu"].includes(params.subpage) === false) {
                navigate(paths.raffleTickets.url);
            }
        }
        return function () { };
    }, [pathname]);
    var setFilters = function () {
        // console.log(drawFilters)
        forceUpdate();
    };
    // console.log("drawFilters.isNew", drawFilters.isNew)
    var shownRaffleDraws = raffleDraws.filter(function (item) {
        var validDraw = item;
        if (drawFilters.categories.length > 0 && !drawFilters.categories.includes(item.draw_categories_id)) {
            validDraw = null;
        }
        if (validDraw && drawFilters.isPopular && item.is_popular === false) {
            validDraw = null;
        }
        if (validDraw && drawFilters.isNew && !item.is_new) {
            validDraw = null;
        }
        if (validDraw && drawFilters.runningOut === true && item.remaining_tickets === 0) {
            validDraw = null;
        }
        if (validDraw) {
            return validDraw;
        }
    });
    gtmViewItemList(shownRaffleDraws, "Çekiliş");
    if (drawFilters.ordering[0] == "sort") {
        var runningOuts = [];
        var cars = shownRaffleDraws.filter(function (el) { return el.draw_categories_sort === 1 && el.remaining_tickets > 0; });
        var motorbike = shownRaffleDraws.filter(function (el) { return el.draw_categories_sort === 2 && el.remaining_tickets > 0; });
        var sorted = shownRaffleDraws.filter(function (el) {
            return el.sort !== 0 && el.draw_categories_sort !== 1 && el.draw_categories_sort !== 2 && el.remaining_tickets > 0;
        });
        var others = shownRaffleDraws.filter(function (el) {
            return el.sort === 0 && el.draw_categories_sort != 1 && el.draw_categories_sort != 2 && el.remaining_tickets > 0;
        });
        // console.log(drawFilters.runningOut);
        if (!drawFilters.runningOut) {
            runningOuts = shownRaffleDraws.filter(function (el) { return el.remaining_tickets === 0; });
        }
        cars = _.orderBy(cars, "ticket_price", "desc");
        motorbike = _.orderBy(motorbike, "ticket_price", "desc");
        sorted = _.orderBy(sorted, "ticket_price", "desc");
        others = _.orderBy(others, "ticket_price", "desc");
        shownRaffleDraws = __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], cars, true), motorbike, true), sorted, true), others, true), runningOuts, true);
    }
    else {
        var runningOuts = [];
        if (!drawFilters.runningOut) {
            runningOuts = shownRaffleDraws.filter(function (el) { return el.remaining_tickets === 0; });
            runningOuts = _.orderBy(runningOuts, drawFilters.ordering[0], drawFilters.ordering[1]);
        }
        var items = shownRaffleDraws.filter(function (el) { return parseInt(el.remaining_tickets) > 0; });
        items = _.orderBy(items, drawFilters.ordering[0], drawFilters.ordering[1]);
        shownRaffleDraws = __spreadArray(__spreadArray([], items, true), runningOuts, true);
    }
    return (_jsxs(_Fragment, { children: [isMobile && (_jsx("div", __assign({ className: "row inner-page-top-tabs" }, { children: _jsxs("div", __assign({ className: "liner-tabs", style: { "--tab-count": auth.member ? "4" : "3" } }, { children: [_jsx("input", { type: "radio", value: "0", checked: params.subpage === undefined, "data-rel": "tab1", id: "raffle-id_tab-1", onChange: function () {
                                navigate(paths.raffleTickets.url);
                            }, name: "tabs" }), _jsx("label", __assign({ className: "tab", htmlFor: "raffle-id_tab-1" }, { children: "\u00C7ekili\u015Fler" })), auth.member && (_jsxs(_Fragment, { children: [_jsx("input", { type: "radio", value: "1", "data-rel": "tab2", checked: params.subpage === "biletlerim", id: "raffle-id_tab-2", onChange: function () {
                                        navigate(paths.raffleTickets.url + "/biletlerim");
                                    }, name: "tabs" }), _jsx("label", __assign({ className: "tab", htmlFor: "raffle-id_tab-2" }, { children: "Biletlerim" }))] })), _jsx("input", { type: "radio", value: "2", "data-rel": auth.member ? "tab3" : "tab2", checked: params.subpage === "sonuclar", id: "raffle-id_tab-3", onChange: function () {
                                navigate(paths.raffleTickets.url + "/sonuclar");
                            }, name: "tabs" }), _jsx("label", __assign({ className: "tab", htmlFor: "raffle-id_tab-3" }, { children: "Sonu\u00E7lar" })), _jsx("input", { type: "radio", value: "3", "data-rel": auth.member ? "tab4" : "tab3", checked: params.subpage === "kazananlar-kulubu", id: "raffle-id_tab-4", onChange: function () {
                                navigate(paths.raffleTickets.url + "/kazananlar-kulubu");
                            }, name: "tabs" }), _jsx("label", __assign({ className: "tab text-center", htmlFor: "raffle-id_tab-4" }, { children: "Kazananlar" })), _jsx("span", { className: "glider" })] })) }))), !isMobile && (_jsx("div", __assign({ className: "row" }, { children: _jsx("div", __assign({ className: "mb-3 mt-1" }, { children: _jsxs("div", __assign({ className: "slider-tabs", style: { "--tab-count": auth.member ? "4" : "3" } }, { children: [_jsx("input", { type: "radio", value: "0", checked: params.subpage === undefined, "data-rel": "tab1", id: "raffle-id_tab-1", onChange: function () {
                                    navigate(paths.raffleTickets.url);
                                }, name: "tabs" }), _jsx("label", __assign({ className: "tab", htmlFor: "raffle-id_tab-1" }, { children: "\u00C7ekili\u015Fler" })), auth.member && (_jsxs(_Fragment, { children: [_jsx("input", { type: "radio", checked: params.subpage === "biletlerim", value: "1", "data-rel": "tab2", id: "raffle-id_tab-2", onChange: function () {
                                            navigate(paths.raffleTickets.url + "/biletlerim");
                                        }, name: "tabs" }), _jsx("label", __assign({ className: "tab", htmlFor: "raffle-id_tab-2" }, { children: "Biletlerim" }))] })), _jsx("input", { type: "radio", checked: params.subpage === "sonuclar", value: "2", "data-rel": auth.member ? "tab3" : "tab2", id: "raffle-id_tab-3", onChange: function () {
                                    navigate(paths.raffleTickets.url + "/sonuclar");
                                }, name: "tabs" }), _jsx("label", __assign({ className: "tab", htmlFor: "raffle-id_tab-3" }, { children: "Sonu\u00E7lar" })), _jsx("input", { type: "radio", checked: params.subpage === "kazananlar-kulubu", value: "3", "data-rel": auth.member ? "tab4" : "tab3", id: "raffle-id_tab-4", onChange: function () {
                                    navigate(paths.raffleTickets.url + "/kazananlar-kulubu");
                                }, name: "tabs" }), _jsx("label", __assign({ className: "tab", htmlFor: "raffle-id_tab-4" }, { children: "Kazananlar Kul\u00FCb\u00FC" })), _jsx("span", { className: "glider" })] })) })) }))), params.subpage === "biletlerim" && _jsx(MyRaffleOrders, {}), params.subpage === "sonuclar" && _jsx(RaffleResults, {}), params.subpage === "kazananlar-kulubu" && _jsx(WinnersRaffle, {}), params.subpage === undefined && (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "row" }, { children: _jsx(DrawFilter, { setFilters: setFilters, shownRaffleDraws: shownRaffleDraws }) })), _jsx("div", __assign({ className: "raffle-page-container" }, { children: _jsx("div", __assign({ className: "row px-md-0 px-lg-0" }, { children: shownRaffleDraws.map(function (item) {
                                return (_jsx("div", __assign({ className: "col-6 col-lg-4 col-md-4 col-xl-3 px-0" }, { children: _jsx(Ticket, { item: item }) }), "raffle-list-item-".concat(item.id)));
                            }) })) }))] })), _jsx(ScrollToTop, {})] }));
}
